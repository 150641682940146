@use '@angular/material' as mat;
@import 'themes/theme';

// default palette colors
$primary: mat.get-color-from-palette(map-get($theme, primary));
$accent: mat.get-color-from-palette(map-get($theme, accent));
$warn: mat.get-color-from-palette(map-get($theme, warn));

// palette contrasts
$primary-contrast: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
$accent-contrast: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
$warn-contrast: mat.get-color-from-palette(map-get($theme, warn), default-contrast);

// primary palette shades
$primaryA700: mat.get-color-from-palette(map-get($theme, primary), 'A700');
$primary50: mat.get-color-from-palette(map-get($theme, primary), 50);
$primary5: mat.get-color-from-palette(map-get($theme, primary), 5);
$primary15: mat.get-color-from-palette(map-get($theme, primary), 15);
$primary50-25-opacity: mat.get-color-from-palette(map-get($theme, primary), 50, 0.25);

// accent palette shades
$accent200: mat.get-color-from-palette(map-get($theme, accent), 200);
$accent400: mat.get-color-from-palette(map-get($theme, accent), 400);

// warn palette shades
$warn50: mat.get-color-from-palette(map-get($theme, warn), 50);

$accent-hover: #313131;

// background color
$background: mat.get-color-from-palette(map-get($theme, background), background);

$input-height: 45px;
